import React from 'react'
import { spacing } from './theme'
import 'styled-components/macro'

export class DroidErrorBoundary extends React.Component {
  state = {
    error: null,
  }

  componentDidCatch(error, errorInfo) {
    // This where I would report an error to a service like [Sentry](https://sentry.io/welcome/)
    console.error(error, errorInfo)
    this.setState({ error })
  }

  render() {
    let { error, children } = this.props

    if (error != null) {
      //  Hopefully you are only seeing this message since you are looking at the source directly
      return (
        <div
          css={`
            align-items: center;
            background-color: white;
            color: black;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: ${spacing.wide}px;

            img {
              max-width: 400px;
              width: 100%;
            }

            p {
              text-align: center;
            }
          `}
        >
          <img
            src={require('./droids.jpg')}
            alt="Three droids. They can't fix this mess though"
          />
          <h1>The app has crashed</h1>
          <p>
            Sorry about this, our{' '}
            <a href="https://starwars.fandom.com/wiki/Comlink">comlink</a> has
            failed and we couldn't talk to our server
          </p>
          <p>You could try reloading the page</p>
        </div>
      )
    }

    return children
  }
}
