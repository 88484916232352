import React from 'react'
import ReactDOM from 'react-dom'
import { createClient, Provider } from 'urql'
import { App } from './App'
import { DroidErrorBoundary } from './DroidErrorBoundary'
import './index.css'

const client = createClient({
  url: 'https://swapi.graph.cool',
})

ReactDOM.render(
  <React.StrictMode>
    <DroidErrorBoundary>
      <Provider value={client}>
        <App />
      </Provider>
    </DroidErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)
