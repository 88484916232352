import React from 'react'
import { borderRadius, colors, spacing } from './theme'
import 'styled-components/macro'

// This controls how close to the end of the page the user needs to scroll before
// `onEndReached` is called.
//
// This threshold allows us to get a head-start on fetching content, hopefully enough so that the
// user never actually gets to see the loading indicator
export const endThreshold = 200

export const InfiniteList = ({
  renderListEmpty,
  renderListFooter,
  items,
  renderItem,
  onEndReached,
  hasMore,
}) => {
  let hasFired = React.useRef(false)
  let handleScroll = React.useCallback(
    (event) => {
      let { scrollTop, scrollHeight, clientHeight } = event.target
      if (
        scrollTop + endThreshold > scrollHeight - clientHeight &&
        hasMore &&
        !hasFired.current
      ) {
        hasFired.current = true
        onEndReached()
      }
    },
    [onEndReached, hasMore],
  )

  React.useEffect(() => {
    if (hasMore) {
      hasFired.current = false
    }
  }, [hasMore, items])

  return (
    <div
      role="list"
      onScroll={handleScroll}
      css={`
        display: flex;
        flex: 1;
        flex-direction: column;
        border: 2px solid ${colors.yellow};
        border-radius: ${borderRadius}px;
        max-height: 900px;
        padding-left: ${spacing.wide}px;
        padding-right: ${spacing.wide}px;
        padding-top: ${spacing.wide}px;
        overflow-y: auto;

        > * {
          margin-bottom: ${spacing.wide * 2.5}px;
        }
      `}
    >
      {(items || []).length === 0 && renderListEmpty()}
      {(items || []).map(renderItem)}
      {renderListFooter && renderListFooter()}
    </div>
  )
}
