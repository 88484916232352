import React, { useEffect } from 'react'
import { useQuery } from 'urql'
import { animated, config, useSpring, useSprings } from 'react-spring'
import { borderRadius, colors, fontSize, spacing } from './theme'
import { InfiniteList } from './InfiniteList'
import { initialState, pagingReducer, pageSize } from './pagingReducer'
import 'styled-components/macro'

const StarBackground = ({ children }) => {
  let [loaded, setLoaded] = React.useState(false)
  // The app may load quicker than the background image.
  // Avoid a jarring 'pop' by fading it in
  let styles = useSpring({
    to: { opacity: loaded ? 1 : 0 },
    delay: 1000,
    config: config.slow,
  })

  return (
    <div
      css={`
        position: relative;
        height: 100%;
        width: 100%;

        .background {
          object-fit: cover;
          height: 100%;
          width: 100%;
          opacity: 0;
        }

        .content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          max-width: 700px;
          margin-left: auto;
          margin-right: auto;
        }
      `}
    >
      <animated.img
        className="background"
        style={styles}
        src={require('./stars.png')}
        onLoad={() => setLoaded(true)}
      />
      <div className="content">{children}</div>
    </div>
  )
}

const LightsaberLoader = () => {
  return (
    <div
      css={`
        margin-left: -135px;
        padding-top: 70px;
        position: relative;

        @keyframes left-to-right {
          to {
            transform: translateX(100px);
          }
        }

        @keyframes twirl {
          to {
            transform: rotate(720deg);
          }
        }

        // Since both 'twirl' and 'left-to-right' animate the 'transform'
        // We cant apply both to the same element (whichever is second will just replace the first)
        // Add a wrapping element so we only apply one 'transform' per element
        .horizontal-wrapper {
          animation: left-to-right 1.5s alternate infinite ease-in-out;
          position: absolute;
          top: 0;
        }

        .saber {
          animation: twirl 3s alternate infinite ease-in-out;
          position: absolute;
          top: 0;
          transform-origin: 5% 90%;
          height: 35px;
          width: 35px;
        }
      `}
    >
      <div className="horizontal-wrapper">
        <svg
          className="saber"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
        >
          <path fill="#ffccbc" d="M15 35l-1-1v-2L40 6h2v2L16 34l-1 1z" />
          <path fill="#ff3d00" d="M14 35h-1v-2L40 6h2v2L15 35h-1z" />
          <path fill="#90a4ae" d="M8 38l2 2-4 3-1-1 3-4z" />
          <path fill="#90a4ae" d="M15 31l2 2-9 10-3-3 10-9z" />
          <path
            fill="#37474f"
            d="M12 33l3 3-7 7-3-3 7-7zm6-6l-4 4 3 3 1-1v-6z"
          />
          <path fill="#37474f" d="M11 32l3 3-2 2-3-3 2-2z" />
        </svg>
      </div>
    </div>
  )
}

const LoadingScreen = () => {
  let style = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
    config: config.slow,
  })
  return (
    <div
      css={`
        align-items: center;
        display: flex;
        justify-content: center;
        padding: ${spacing.wide}px;
        height: 100%;
        width: 100%;

        p {
          color: ${colors.blue};
          font-size: ${fontSize.body}px;
        }
      `}
    >
      <animated.p style={style}>
        A long time ago in a galaxy far, far away....
      </animated.p>
    </div>
  )
}

const LabelledDetail = ({ label, detail }) => {
  return (
    <div
      css={`
        .label {
          color: ${colors.grey};
          font-weight: bold;
          font-size: ${fontSize.label}px;
          letter-spacing: 1px;
          margin-bottom: ${spacing.small}px;
          text-transform: uppercase;
        }

        .detail {
          color: white;
          font-size: ${fontSize.body}px;
          margin-bottom: ${spacing.medium}px;
        }
      `}
    >
      <div className="label">{label}</div>
      <div className="detail">{detail}</div>
    </div>
  )
}

const Button = ({ onClick, children }) => (
  <button
    css={`
      align-items: center;
      justify-content: center;
      background-color: ${colors.yellow};
      border: none;
      border-radius: ${borderRadius}px;
      color: black;
      display: flex;
      flex-direction: row;
      font-size: ${fontSize.label}px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      padding: 10px;
      width: 100%;
    `}
    onClick={onClick}
  >
    {children}
  </button>
)

// Almost every front-end project I create ends up using something like [Luxon](https://moment.github.io/luxon/) to handle dates since:
// - Date and Time are notoriously difficult to work with
// - The built in Date object is notoriously quirky
// Since this app is so small, and this is the _only_ place that interacts with dates
// I decided to forgo using it here.
const formatDate = (dateLike) => {
  let date = new Date(dateLike)
  return date.toLocaleDateString({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

const SearchResults = ({
  films,
  filter,
  hasMore,
  isFetching,
  onSelectFilm,
  onEndReached,
  setFilter,
}) => {
  let footerStyle = useSpring({ opacity: isFetching && hasMore ? 1 : 0 })

  return (
    <div
      css={`
        padding-left: ${spacing.wide}px;
        padding-right: ${spacing.wide}px;
        flex-direction: column;
        display: flex;
        height: 100%;
        overflow: hidden;

        input {
          background-color: transparent;
          border: 2px solid ${colors.yellow};
          border-radius: 5px;
          color: ${colors.yellow};
          display: flex;
          flex-grow: 0;
          font-size: 19px;
          margin-top: ${spacing.wide}px;
          margin-bottom: ${spacing.wide}px;
          padding: 10px;

          ::placeholder {
            color: ${colors.grey};
          }
        }

        .no-results-explanation {
          color: ${colors.yellow};
          font-size: ${fontSize.header}px;
        }
      `}
    >
      <input
        placeholder="Filter by title"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      />
      <InfiniteList
        items={films}
        hasMore={hasMore}
        onEndReached={onEndReached}
        renderListEmpty={() =>
          !isFetching && (
            <div className="no-results-explanation">
              <p>These are not the films you are looking for.</p>
              <p>
                But seriously, we couldn't find any films that matched your
                search.
              </p>
              <p>
                Our data is a little (a lot) behind the times so maybe we just
                haven't updated it yet.
              </p>
            </div>
          )
        }
        renderListFooter={() => {
          return (
            <animated.div
              css={`
                display: flex;
                justify-content: center;
              `}
              style={footerStyle}
            >
              <LightsaberLoader />
            </animated.div>
          )
        }}
        renderItem={(film) => {
          let { id, director, episodeId, title, releaseDate } = film
          return (
            <div
              key={id}
              css={`
                .title {
                  color: ${colors.yellow};
                  font-weight: bold;
                  font-size: ${fontSize.header}px;
                  margin-bottom: ${spacing.medium}px;
                }
              `}
            >
              <div className="title">{title}</div>
              <LabelledDetail label="Episode" detail={episodeId} />
              <LabelledDetail
                label="Released"
                detail={formatDate(releaseDate)}
              />
              <LabelledDetail label="Director" detail={director} />
              <Button onClick={() => onSelectFilm(film)}>Play</Button>
            </div>
          )
        }}
      />
    </div>
  )
}

const BackIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" {...props}>
      <path d="M94 46H14l29-29v-5h-5L4 48c-2 2-2 4 0 5l34 35h5v-5L14 53h80c2 0 3-1 3-3 1-2-1-4-3-4z" />
    </svg>
  )
}

const useAnimationFrame = (callback) => {
  const requestRef = React.useRef(null)
  const previousTimeRef = React.useRef()
  const animate = (time) => {
    if (previousTimeRef.current != null) {
      const deltaTime = time - previousTimeRef.current
      callback(deltaTime)
    }
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
    // Usually omitting a function as a dependency is a mistake: https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
    // Here its ok as the `animate` function only closes over the references we create just before
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

const pixelsPerMillisecond = 1 / 15

export const OpeningCrawl = ({ text, onBack }) => {
  const content = React.useRef()
  const offset = React.useRef(0)
  useAnimationFrame((delta) => {
    const newOffset = offset.current - pixelsPerMillisecond * delta
    content.current.style.transform = `translateY(${newOffset}px)`
    offset.current = newOffset
  })
  useEffect(() => {
    return () => {
      // Reset the offset when the text changes
      offset.current = 0
    }
  }, [text])

  return (
    <div
      css={`
        display: flex;
        align-items: flex-start;
        height: 100%;
        position: relative;
        overflow: hidden;
        text-align: center;

        .back-icon {
          padding: ${spacing.wide}px;
        }

        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0) 100%
          );
        }

        .content-container {
          position: absolute;
          top: -2800px;
          bottom: 0;
          left: 0;
          right: 0;
          transform-origin: 50% 100%;
          transform: perspective(300px) rotateX(20deg);
        }

        .content {
          padding-top: 3500px;
        }

        p {
          color: ${colors.yellow};
          line-height: ${fontSize.header}px;
          font-size: ${fontSize.header}px;
        }
      `}
    >
      <BackIcon
        className="back-icon"
        fill={colors.yellow}
        height={80}
        onClick={onBack}
      />
      <div className="content-container">
        <div className="content" ref={content}>
          {text.split(`\n`).map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        <div className="overlay" />
      </div>
    </div>
  )
}

const screens = {
  loading: 'loading',
  results: 'results',
  playOpeningCrawl: 'playOpeningCrawl',
}

const activeScreenStyles = {
  opacity: 1,
  zIndex: 1,
}

const inactiveScreenStyles = {
  opacity: 0,
  zIndex: 0,
}

const FindMatchingMovies = `
  query FindMatchingMovies($searchString: String!, $first: Int!, $after: String) {
    allFilms(
      filter: {
        title_contains: $searchString,         
      }, 
      first: $first,
      after: $after,
      orderBy: releaseDate_ASC,
    ) {
      id
      title
      director
      releaseDate
      episodeId
      characters {
        id
        name   
        species {
          id
          name
        }
      }
      openingCrawl
    }
  }
`

const useFilms = (filter) => {
  let [{ films, after, hasMore }, dispatch] = React.useReducer(
    pagingReducer,
    initialState,
  )
  const [{ fetching, error, data }] = useQuery({
    query: FindMatchingMovies,
    variables: {
      searchString: filter,
      first: pageSize,
      after,
    },
  })

  React.useEffect(() => {
    dispatch({ kind: 'reset' })
  }, [filter])

  React.useEffect(() => {
    if (data != null) {
      dispatch({
        kind: 'new-page',
        payload: data.allFilms,
      })
    }
  }, [data])

  let fetchMore = () => {
    dispatch({ kind: 'fetch-more' })
  }

  return { films, fetching, error, hasMore, fetchMore }
}

export const App = () => {
  let [filter, setFilter] = React.useState('')
  const result = useFilms(filter)

  if (result.error) {
    // Let the error boundary deal with this
    // Since the app is so small, there is no point trying to recover from network failures
    // as there is no other functionality and no steps the user can take to resolve the problem
    throw result.error
  }

  let [crawlText, setCrawlText] = React.useState('')
  let [screen, setScreen] = React.useState(screens.loading)
  React.useEffect(() => {
    // Once we have fetched a result set from our API we clear the loading screen
    // We only want this screen to be shown _once_, the first time we are no longer 'fetching'
    if (screen === screens.loading && !result.fetching) {
      // Add a delay before clearing the loading screen so that users
      // have the opportunity to read the message before it is hidden.
      setTimeout(() => {
        setScreen(screens.results)
      }, 3000)
    }
  }, [screen, result.fetching])

  let [loadingScreenStyles, resultsStyles, playOpeningStyles] = useSprings(3, [
    screen === screens.loading ? activeScreenStyles : inactiveScreenStyles,
    screen === screens.results ? activeScreenStyles : inactiveScreenStyles,
    screen === screens.playOpeningCrawl
      ? activeScreenStyles
      : inactiveScreenStyles,
  ])

  return (
    <StarBackground>
      <div
        css={`
          position: relative;
          height: 100%;
          width: 100%;
        `}
      >
        <animated.div
          css={`
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 0;
          `}
          style={loadingScreenStyles}
        >
          <LoadingScreen />
        </animated.div>
        <animated.div
          css={`
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 0;
          `}
          style={resultsStyles}
        >
          <SearchResults
            films={result.films}
            isFetching={result.fetching}
            hasMore={result.hasMore}
            filter={filter}
            setFilter={setFilter}
            onSelectFilm={(film) => {
              setCrawlText(film.openingCrawl)
              setScreen(screens.playOpeningCrawl)
            }}
            onEndReached={() => {
              console.info('end reached fetcing more')
              result.fetchMore()
            }}
          />
        </animated.div>
        <animated.div
          css={`
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 0;
          `}
          style={playOpeningStyles}
        >
          <OpeningCrawl
            text={crawlText}
            onBack={() => setScreen(screens.results)}
          />
        </animated.div>
      </div>
    </StarBackground>
  )
}
