export const borderRadius = 5

export const colors = {
  grey: '#AAA',
  blue: '#75e7e3',
  yellow: '#FBE954',
  red: '#F10017',
}

export const fontSize = {
  label: 15,
  body: 20,
  header: 28,
}

export const spacing = {
  small: 8,
  medium: 16,
  wide: 24,
}
