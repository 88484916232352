export const pageSize = 3

export const initialState = {
  after: null,
  films: [],
  hasMore: true,
}

export const pagingReducer = (state, action) => {
  if (action.kind === 'reset') {
    return initialState
  }
  if (action.kind === 'new-page') {
    let page = action.payload
    return {
      ...state,
      films: [...state.films, ...page],
      // When we get back a result set with less than the number of films requested
      // we have reached the last page so shouldn't try to fetch any more
      hasMore: page.length === pageSize,
    }
  }
  if (action.kind === 'fetch-more') {
    let lastFilmId = state.films[state.films.length - 1]?.id
    return {
      ...state,
      after: lastFilmId,
    }
  }

  throw new Error(`Unrecognized action ${action.kind}`)
}
